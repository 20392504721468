import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { StyledFlex, StyledText, StyledTextareaAutosize } from '../../../styles/styled';

import { StyledBaseTextInput } from './StyledBaseTextInput';

const BaseTextInput = ({
  borderColor,
  inputRef,
  inputHeight,
  textAlign,
  showLength,
  maxLength,
  value,
  placeholderFontSize,
  autoSize,
  onChange = () => {},
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value);
    }
  }, [value]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);

    onChange(e);
  };

  const TextControl = autoSize ? StyledTextareaAutosize : StyledBaseTextInput;

  return (
    <StyledFlex position="relative">
      <StyledFlex direction="row" flex="auto" width="100%" height={inputHeight}>
        <TextControl
          value={inputValue}
          onChange={handleInputChange}
          maxLength={maxLength}
          {...props}
          borderColor={borderColor}
          height={inputHeight}
          textAlign={textAlign}
          ref={inputRef}
          placeholderFontSize={placeholderFontSize}
        />
      </StyledFlex>
      {showLength && (
        <StyledFlex position="absolute" bottom={-20} right={0} justifyContent="flex-end" direction="row">
          <StyledText size={12} lh={15}>
            {inputValue?.length}
            {maxLength ? `/${maxLength}` : ''} characters
          </StyledText>
        </StyledFlex>
      )}
    </StyledFlex>
  );
};

export default BaseTextInput;

BaseTextInput.propTypes = {
  borderColor: PropTypes.string,
  inputHeight: PropTypes.string,
  textAlign: PropTypes.string,
  inputRef: PropTypes.object,
  onChange: PropTypes.func,
};

import React, { Suspense } from 'react';
import { useRecoilState } from 'recoil';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import Spinner from './Components/shared/Spinner/Spinner';
import { Fallback } from './Components/TechPages/Fallback';
import { Route, Routes } from 'react-router-dom';
import { DynamicRoutes } from './DynamicRoutes';
import 'react-toastify/dist/ReactToastify.css';
import CenterModalFixed from './Components/shared/REDISIGNED/modals/CenterModalFixed/CenterModalFixed';
import { tabletWarningModalOpenState } from './store';
import MobileTabletWarning from './Components/WarningPages/MobileTabletWarning';
import routes from './config/routes';

const PublicFormPage = React.lazy(() => import('./Components/PublicFormPage/PublicFormPage'));

const App = () => {
  const [isTabletWarningModalOpen, setIsTabletWarningModalOpen] = useRecoilState(tabletWarningModalOpenState);

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <ToastContainer enableMultiContainer />
      <Suspense fallback={<Spinner global />}>
        <Routes>
          <Route path={routes.WARNING} element={<MobileTabletWarning />} />

          {/* All public routes independent of login. Will not call any user/page/flags related APIs. Just rendering pages */}
          <Route path="/forms/:organizationId/:processId" element={<PublicFormPage />} />

          {/* Dynamic routes */}
          <Route path="*" element={<DynamicRoutes />} />
        </Routes>
      </Suspense>
      {isTabletWarningModalOpen ? (
        <CenterModalFixed
          open={isTabletWarningModalOpen}
          onClose={() => setIsTabletWarningModalOpen(false)}
          enableScrollbar={false}
          maxWidth="653"
          blurBackground
          zIndexRoot="9999999999"
        >
          <MobileTabletWarning isTabletView />
        </CenterModalFixed>
      ) : null}
    </ErrorBoundary>
  );
};

export default App;

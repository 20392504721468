export const ALL_STEPS = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_4: 4,
  STEP_5: 5,
  STEP_6: 6,
};

export const SIMPLYASK_LINK = 'https://simplyask.ai';
export const SIMPLYASK_TERMS_AND_CONDITIONS_LINK = `${SIMPLYASK_LINK}/terms-of-service`;
export const SIMPLYASK_PRIVACY_POLICY_LINK = `${SIMPLYASK_LINK}/privacy-policy`;
export const SIMPLYASK_PRICING_LINK = `${SIMPLYASK_LINK}/symphona/pricing`;
export const QUERY_PARAM_KEY_PROMO = 'promo';
export const QUERY_PARAM_TEMPLATE_ID = 'templateId'; //TODO: update if needed

export const COMMON_PHONE_NUMBER_INPUT_PROPS = {
  formatPhoneNumber: true,
  maxLength: 12,
  borderRadius: '5px',
};

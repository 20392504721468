export const media = {
  xxl: '@media (max-width: 2070px)',
  xl: '@media (min-width: 1921px)',
  lg: '@media (max-width: 1920px)',
  md: '@media (max-width: 1440px)',
  sm: '@media (max-width: 1200px)',
  xs: '@media (max-width: 1024px)',
  xss: '@media (max-width: 768px)',
  xsss: '@media (max-width: 480px)',
};

import { useMutation } from '@tanstack/react-query';
import { applyTemplate } from '../../../Services/axios/authAxios';

const useUtilizeTemplate = ({ onSuccess, onError } = {}) => {
  const { mutate: utilizeTemplate, isPending: isTemplateUtilizing } = useMutation({
    mutationFn: async ({ templateId, orgId }) => applyTemplate(templateId, orgId),
    onSuccess,
    onError,
  });

  return {
    utilizeTemplate,
    isTemplateUtilizing,
  };
};

export default useUtilizeTemplate;

import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { StyledFlex, StyledIconButton, StyledText } from '../shared/styles/styled';
import SymphonaIcon from '../../Assets/images/SymphonLogo.svg?component';
import WarningImage from '../../Assets/images/warningImage.svg?component';
import Checkbox from '../shared/REDISIGNED/controls/Checkbox/Checkbox';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { dontShowTabletWarningModalAgainState, tabletWarningModalOpenState } from '../../store';
import { StyledButton } from '../shared/REDISIGNED/controls/Button/StyledButton';
import { useNavigate } from 'react-router-dom';
import { DEVICES, getDeviceType } from '../../utils/helperFunctions';
import routes from '../../config/routes';

const MobileTabletWarning = ({ isTabletView = false }) => {
  const { colors, boxShadows } = useTheme();
  const [isDontShowTabletWarning, setIsDontShowTabletWarning] = useRecoilState(dontShowTabletWarningModalAgainState);
  const setIsTabletWarningModalOpen = useSetRecoilState(tabletWarningModalOpenState);
  const navigate = useNavigate();

  useEffect(() => {
    const deviceType = getDeviceType();

    if (deviceType === DEVICES.DESKTOP || deviceType === DEVICES.TABLET) navigate(routes.DEFAULT);
  }, []);

  const handleTabletModalConfirm = () => {
    localStorage.setItem('dontShowTabletWarning', isDontShowTabletWarning);
    setIsTabletWarningModalOpen(false);
  };

  return isTabletView ? (
    <StyledFlex p="50px" gap="30px" boxShadow={boxShadows.warningHeader} borderRadius="20px">
      <StyledIconButton
        bgColor="transparent"
        hoverBgColor="transparent"
        size="small"
        onClick={() => window.open('https://www.simplyask.ai/symphona', '_blank', 'noopener,noreferrer')}
      >
        <SymphonaIcon width="183px" height="35px" />
      </StyledIconButton>
      <StyledText weight={700} size={25} textAlign="center">
        Symphona Is Not Optimized For Tablets
      </StyledText>
      <StyledText textAlign="center">
        Symphona is not optimized for smaller screens, but many pages can still be access on Tablet. If you would like
        to login to your Symphona account, it is recommended to open this page on your Desktop.
      </StyledText>
      <StyledText textAlign="center">
        By continuing to the page, you acknowledge that some functionality may not work as expected.
      </StyledText>
      <StyledFlex gap="10px" direction="row" alignSelf="center" alignItems="center" justifyContent="center">
        <Checkbox
          checked={isDontShowTabletWarning}
          onChange={() => setIsDontShowTabletWarning(!isDontShowTabletWarning)}
        />{' '}
        <StyledText>Do not show this message again</StyledText>
      </StyledFlex>

      <StyledFlex alignSelf="center" width="226px" height="38px">
        <StyledButton variant="contained" onClick={handleTabletModalConfirm}>
          Confirm and Continue
        </StyledButton>
      </StyledFlex>
    </StyledFlex>
  ) : (
    <StyledFlex display="flex" height="100vh">
      <StyledFlex
        p="15px 30px"
        height="60px"
        boxShadow={boxShadows.warningHeader}
        alignItems="start"
        justifyContent="center"
        zIndex={1}
      >
        <StyledIconButton
          bgColor="transparent"
          hoverBgColor="transparent"
          size="small"
          onClick={() => window.open('https://www.simplyask.ai/symphona', '_blank', 'noopener,noreferrer')}
        >
          <SymphonaIcon width="157px" height="30px" />
        </StyledIconButton>
      </StyledFlex>
      <StyledFlex
        p="10px 30px"
        flex="1"
        alignItems="center"
        justifyContent="center"
        backgroundColor={colors.blueMintCream}
      >
        <StyledFlex
          borderRadius="20px"
          gap="30px"
          p="50px 30px"
          backgroundColor={colors.white}
          boxShadow={boxShadows.warningHeader}
        >
          <StyledFlex as="span" alignItems="center">
            <WarningImage />
          </StyledFlex>

          <StyledText weight={700} size={25} textAlign="center">
            Symphona Cannot Be Accessed On Mobile
          </StyledText>

          <StyledText textAlign="center">
            Symphona is not optimized for smaller screens. If you would like to login to your Symphona account, please
            open this page on a Desktop device.
          </StyledText>
        </StyledFlex>
      </StyledFlex>
    </StyledFlex>
  );
};

export default MobileTabletWarning;

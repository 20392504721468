/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SymphonaIconText = (props) => {
  const { colors } = useTheme();

  return (
    <SvgIcon {...props} color={colors.primary} viewBox="0 0 24 4.5">
      <path d="M1.05 3.37c-.22-.06-.4-.15-.52-.26l.2-.44c.12.1.27.18.46.24.18.06.36.1.55.1.22 0 .4-.04.5-.12a.32.32 0 0 0 .17-.29.28.28 0 0 0-.09-.21.66.66 0 0 0-.24-.14l-.4-.1a4.53 4.53 0 0 1-.56-.17.93.93 0 0 1-.37-.26.7.7 0 0 1-.16-.48c0-.17.05-.32.14-.46.1-.14.23-.25.42-.33a2.08 2.08 0 0 1 1.7.15l-.18.44A1.76 1.76 0 0 0 1.82.8a.95.95 0 0 0-.5.11.35.35 0 0 0-.16.3c0 .1.03.16.1.22.06.05.14.1.23.13.1.03.23.07.4.1l.55.17c.15.06.28.15.38.26.1.12.15.28.15.47a.8.8 0 0 1-.13.46.94.94 0 0 1-.42.33 1.7 1.7 0 0 1-.68.12c-.24 0-.47-.03-.69-.1z" />
      <path d="M1.74 3.5c-.24 0-.47-.04-.7-.1a1.4 1.4 0 0 1-.53-.28H.5l.22-.5.02.03a1.65 1.65 0 0 0 1 .33c.22 0 .38-.04.5-.1.1-.08.15-.16.15-.28a.25.25 0 0 0-.09-.2.64.64 0 0 0-.23-.12c-.1-.04-.22-.07-.39-.11A4.62 4.62 0 0 1 1.11 2a.96.96 0 0 1-.38-.27.73.73 0 0 1-.16-.5c0-.16.05-.32.14-.47.1-.14.24-.25.43-.33a2.02 2.02 0 0 1 1.72.14l.02.02-.2.48-.02-.01a1.75 1.75 0 0 0-.84-.24.87.87 0 0 0-.48.11.33.33 0 0 0-.16.28c0 .08.03.15.09.2.06.05.14.1.23.13l.39.1c.23.06.42.11.56.17.15.06.28.15.39.27.1.12.16.28.16.48a.8.8 0 0 1-.15.48.96.96 0 0 1-.42.33c-.2.09-.43.13-.7.13zM.56 3.1c.12.1.29.19.5.25a2.15 2.15 0 0 0 1.35-.02.92.92 0 0 0 .4-.32.79.79 0 0 0 .14-.45.64.64 0 0 0-.15-.45.94.94 0 0 0-.36-.26 4.18 4.18 0 0 0-.56-.16l-.4-.1a.72.72 0 0 1-.24-.14.3.3 0 0 1-.1-.24c0-.13.05-.24.17-.32a.91.91 0 0 1 .51-.12 1.78 1.78 0 0 1 .83.23l.17-.4a1.52 1.52 0 0 0-.45-.18 2.2 2.2 0 0 0-.54-.07c-.27 0-.5.04-.67.12a.91.91 0 0 0-.4.32.8.8 0 0 0-.14.45c0 .2.05.35.15.46.1.12.22.2.36.26l.56.16.4.11c.1.03.18.08.25.14.06.06.1.14.1.23 0 .13-.06.24-.18.31a.98.98 0 0 1-.52.12 1.71 1.71 0 0 1-1-.33l-.18.4zm5.04-2L4.5 3.62c-.1.25-.22.43-.37.54a.9.9 0 0 1-.53.15c-.11 0-.23-.02-.34-.06a.77.77 0 0 1-.28-.15l.22-.4a.6.6 0 0 0 .4.16c.1 0 .17-.03.24-.08A.66.66 0 0 0 4 3.53l.04-.09L3.03 1.1h.57l.73 1.72.74-1.72h.53z" />
      <path d="M3.6 4.34c-.12 0-.23-.02-.35-.06a.78.78 0 0 1-.29-.16l-.01-.02.24-.44.02.02a.57.57 0 0 0 .39.15c.09 0 .16-.02.22-.07A.64.64 0 0 0 4 3.52l.03-.08L3 1.07h.62l.72 1.7.73-1.7h.57l-1.1 2.56c-.1.26-.24.44-.38.55a.92.92 0 0 1-.55.16zM3 4.09a1.06 1.06 0 0 0 .6.2c.2 0 .38-.05.52-.15.14-.1.26-.28.36-.53l1.08-2.49H5.1l-.76 1.76-.75-1.76h-.51l1 2.32-.04.1a.69.69 0 0 1-.18.26.4.4 0 0 1-.25.08.63.63 0 0 1-.4-.15zm6.6-2.76c.18.17.26.42.26.77v1.33h-.54V2.17c0-.2-.05-.36-.14-.47-.09-.1-.22-.15-.39-.15a.57.57 0 0 0-.44.18.76.76 0 0 0-.17.52v1.18h-.54V2.17c0-.2-.05-.36-.14-.47-.09-.1-.22-.15-.39-.15a.57.57 0 0 0-.44.18.76.76 0 0 0-.17.52v1.18h-.54V1.1h.52v.3a.9.9 0 0 1 .33-.25 1.18 1.18 0 0 1 .9.02c.13.07.24.16.32.3.1-.13.22-.23.37-.3.15-.06.32-.1.5-.1.3 0 .53.09.7.26z" />
      <path d="M9.89 3.46h-.6v-1.3c0-.2-.04-.34-.13-.44-.08-.1-.2-.15-.37-.15a.55.55 0 0 0-.42.18.73.73 0 0 0-.16.5v1.2h-.6V2.18c0-.2-.04-.35-.13-.45-.08-.1-.2-.15-.37-.15a.55.55 0 0 0-.42.17.77.77 0 0 0-.16.5v1.22h-.6v-2.4h.57v.26a.88.88 0 0 1 .3-.2 1.23 1.23 0 0 1 .92.02c.13.06.23.15.31.27.1-.11.22-.2.36-.27.16-.07.33-.1.52-.1.3 0 .54.09.71.26.18.17.27.44.27.78v1.37zm-.55-.05h.5V2.09c0-.33-.08-.58-.25-.75a.93.93 0 0 0-.68-.25c-.18 0-.35.04-.5.1a.95.95 0 0 0-.36.29l-.02.02L8 1.47a.74.74 0 0 0-.31-.28c-.13-.06-.29-.1-.46-.1-.15 0-.3.03-.42.09a.83.83 0 0 0-.32.23l-.05.05v-.34h-.47v2.29h.5V2.25a.8.8 0 0 1 .17-.54.6.6 0 0 1 .46-.19c.18 0 .31.06.41.17.1.1.14.27.14.47v1.25h.5V2.25c0-.23.06-.41.17-.54a.6.6 0 0 1 .46-.19c.18 0 .32.06.41.17.1.1.14.27.14.47v1.25zm2.81-2.19c.18.1.32.24.42.42.1.18.15.39.15.62 0 .24-.05.45-.15.63a.95.95 0 0 1-.42.42c-.18.1-.38.15-.61.15a.94.94 0 0 1-.75-.31v1.13h-.55V1.1h.52v.3c.1-.1.2-.19.34-.25.13-.05.28-.08.44-.08.23 0 .43.05.6.15zm-.18 1.57a.7.7 0 0 0 .2-.53.72.72 0 0 0-.2-.52.66.66 0 0 0-.5-.2.7.7 0 0 0-.35.08.65.65 0 0 0-.25.26.78.78 0 0 0-.09.38c0 .15.03.28.1.39s.14.2.24.26c.1.06.23.09.36.09a.7.7 0 0 0 .5-.2z" />
      <path d="M10.81 4.3h-.6V1.07h.58v.27a.88.88 0 0 1 .3-.2c.13-.06.28-.1.45-.1.23 0 .44.06.62.16s.33.24.43.43a1.32 1.32 0 0 1 0 1.27c-.1.19-.25.33-.43.44-.18.1-.4.15-.62.15-.3 0-.54-.1-.73-.28v1.1zm-.54-.04h.5V3.09l.04.04c.18.2.42.3.73.3.22 0 .42-.04.6-.14.17-.1.31-.23.4-.4.1-.19.16-.4.16-.63a1.16 1.16 0 0 0-.56-1.02 1.22 1.22 0 0 0-1.03-.06.83.83 0 0 0-.33.24l-.04.05v-.35h-.47v3.14zm1.2-1.24a.73.73 0 0 1-.36-.1.67.67 0 0 1-.26-.26.8.8 0 0 1-.1-.4c0-.14.04-.28.1-.4a.8.8 0 0 1 .26-.26c.1-.06.23-.09.37-.09.2 0 .38.07.51.2.13.15.2.33.2.55 0 .23-.07.41-.2.55-.13.14-.3.2-.51.2zm0-1.46a.68.68 0 0 0-.34.08.63.63 0 0 0-.24.25c-.06.1-.09.23-.09.38s.03.26.1.37c.05.1.13.19.23.25.1.05.22.08.35.08.19 0 .35-.06.48-.2a.7.7 0 0 0 .18-.5.7.7 0 0 0-.18-.52.64.64 0 0 0-.48-.2zm3.67-.23c.17.17.26.43.26.76v1.34h-.54V2.16c0-.2-.05-.35-.15-.46a.54.54 0 0 0-.4-.15.66.66 0 0 0-.49.18.76.76 0 0 0-.17.52v1.18h-.55V.2h.55v1.18a.9.9 0 0 1 .33-.22c.14-.05.28-.08.45-.08.3 0 .53.09.7.26z" />
      <path d="M15.43 3.46h-.6v-1.3c0-.2-.04-.34-.14-.44-.09-.1-.22-.15-.39-.15a.6.6 0 0 0-.46.18c-.11.11-.17.28-.17.5v1.2h-.6V.17h.6v1.15a.93.93 0 0 1 .3-.18c.14-.06.3-.08.46-.08a1 1 0 0 1 .72.26c.18.18.28.44.28.78v1.37zm-.55-.05h.5V2.09c0-.33-.09-.58-.26-.74a.96.96 0 0 0-.7-.26c-.15 0-.3.03-.43.08a.86.86 0 0 0-.32.21l-.04.05V.2h-.5v3.2h.5V2.25c0-.23.06-.41.18-.54a.66.66 0 0 1 .49-.19c.18 0 .33.06.43.17.1.1.15.26.15.47v1.25zm1.51-.1c-.2-.1-.34-.24-.45-.43a1.2 1.2 0 0 1-.16-.61c0-.24.06-.44.16-.62.11-.18.26-.32.45-.43.19-.1.4-.15.64-.15.23 0 .45.05.64.15a1.13 1.13 0 0 1 .6 1.05c0 .23-.05.43-.16.61-.1.19-.25.33-.44.43-.19.1-.4.15-.64.15s-.45-.05-.64-.15zm1.14-.52a.7.7 0 0 0 .2-.52c0-.22-.07-.4-.2-.53-.14-.14-.3-.2-.5-.2s-.37.06-.5.2a.73.73 0 0 0-.2.53c0 .21.07.39.2.52.13.14.3.2.5.2s.36-.06.5-.2z" />
      <path d="M17.03 3.49c-.24 0-.46-.05-.65-.16-.2-.1-.35-.25-.46-.43-.1-.19-.16-.4-.16-.63a1.15 1.15 0 0 1 .62-1.06c.19-.11.4-.16.65-.16s.46.05.65.15a1.15 1.15 0 0 1 .62 1.07c0 .23-.06.44-.17.63-.1.18-.26.33-.45.43-.2.1-.41.16-.65.16zm0-2.4c-.24 0-.45.06-.63.16a1.1 1.1 0 0 0-.6 1.02c0 .22.06.43.16.6.1.18.25.32.44.42.18.1.4.15.63.15.23 0 .44-.05.63-.15.18-.1.33-.24.43-.42.1-.17.16-.38.16-.6 0-.23-.05-.43-.16-.6a1.1 1.1 0 0 0-.43-.42c-.19-.1-.4-.16-.63-.16zm0 1.93a.69.69 0 0 1-.52-.2.76.76 0 0 1-.2-.55c0-.23.07-.41.2-.55.14-.14.3-.2.52-.2.2 0 .38.06.51.2.14.14.2.32.2.55 0 .22-.06.4-.2.54a.7.7 0 0 1-.51.2zm0-1.46a.6.6 0 0 0-.48.2.7.7 0 0 0-.2.5c0 .22.07.39.2.52.12.13.28.2.48.2.19 0 .35-.07.48-.2a.7.7 0 0 0 .19-.51.7.7 0 0 0-.2-.52.65.65 0 0 0-.47-.2zm3.67-.23c.18.17.27.43.27.76v1.34h-.54V2.16c0-.2-.05-.35-.15-.46a.54.54 0 0 0-.4-.15.66.66 0 0 0-.49.18.76.76 0 0 0-.17.52v1.18h-.55V1.1h.52v.3c.1-.1.2-.19.34-.25.14-.05.3-.08.47-.08.3 0 .53.09.7.26z" />
      <path d="M21 3.46h-.6v-1.3c0-.2-.04-.34-.14-.44-.09-.1-.22-.15-.39-.15a.6.6 0 0 0-.46.18c-.11.11-.17.28-.17.5v1.2h-.6V1.08h.58v.27a.89.89 0 0 1 .3-.2c.14-.06.3-.1.48-.1a1 1 0 0 1 .72.27c.18.18.28.44.28.78v1.37zm-.55-.05h.5V2.09c0-.33-.09-.58-.26-.74a.96.96 0 0 0-.7-.26c-.16 0-.31.03-.45.09a.84.84 0 0 0-.33.23l-.04.05v-.34h-.47v2.29h.5V2.25c0-.23.06-.41.18-.54a.66.66 0 0 1 .5-.19c.17 0 .32.06.42.17.1.1.15.26.15.47v1.25zm2.75-2.09c.19.16.28.41.28.74v1.37h-.52v-.28a.63.63 0 0 1-.29.23.99.99 0 0 1-.44.08 1.1 1.1 0 0 1-.46-.09.71.71 0 0 1-.3-.25.63.63 0 0 1-.11-.36c0-.2.07-.37.23-.5s.4-.2.74-.2h.6v-.03c0-.16-.05-.29-.15-.37-.1-.1-.24-.13-.43-.13-.13 0-.26.02-.39.06a.95.95 0 0 0-.32.17l-.21-.4c.12-.1.27-.16.44-.21.17-.05.35-.08.54-.08.35 0 .61.08.8.25zm-.5 1.65a.47.47 0 0 0 .23-.27v-.27h-.56c-.32 0-.48.1-.48.31 0 .1.04.18.12.24.08.06.19.09.33.09s.26-.03.36-.1z" />
      <path d="M22.23 3.49a.99.99 0 0 1-.47-.1.73.73 0 0 1-.32-.25.66.66 0 0 1-.1-.38c0-.21.07-.39.23-.52s.42-.2.76-.2h.58a.5.5 0 0 0-.14-.37.66.66 0 0 0-.42-.12c-.13 0-.26.02-.38.06a.92.92 0 0 0-.31.17l-.03.01-.23-.44.01-.01c.13-.1.28-.17.45-.22a2 2 0 0 1 .55-.07c.35 0 .62.08.81.25.19.17.28.43.28.76v1.4h-.56v-.24a.69.69 0 0 1-.26.18c-.12.06-.28.09-.45.09zm.1-1.4c-.33 0-.57.06-.73.19a.6.6 0 0 0-.22.48c0 .13.03.25.1.35.07.1.17.19.3.24.12.06.28.09.45.09s.31-.03.43-.08a.62.62 0 0 0 .28-.22l.04-.07v.34h.47V2.06c0-.32-.09-.56-.26-.72-.18-.16-.44-.24-.78-.24-.19 0-.37.02-.54.07-.16.04-.3.11-.41.2l.19.35a1 1 0 0 1 .3-.15c.13-.05.27-.07.4-.07.2 0 .35.05.45.14.1.1.15.22.15.4v.05h-.62zm.01 1a.57.57 0 0 1-.34-.1.3.3 0 0 1-.13-.25c0-.22.17-.33.5-.33h.58v.3a.54.54 0 0 1-.23.28.73.73 0 0 1-.38.1zm.03-.63c-.3 0-.45.09-.45.28 0 .1.03.17.1.22.08.05.19.08.32.08s.25-.03.35-.09c.1-.06.17-.14.22-.25v-.24h-.54z" />
    </SvgIcon>
  );
};

export default SymphonaIconText;

import { useQuery } from '@tanstack/react-query';
import { getTemplateById } from '../../../Services/axios/authAxios';
import { useSearchParams } from 'react-router-dom';
import { QUERY_PARAM_TEMPLATE_ID } from '../utils/constants';

export const GET_TEMPLATE_BY_ID = 'GET_TEMPLATE_BY_ID';

export const useGetTemplateById = () => {
  const [searchParam] = useSearchParams();
  const templateId = searchParam.get(QUERY_PARAM_TEMPLATE_ID);

  const {
    data: template,
    error,
    isLoading: isTemplateLoading,
  } = useQuery({
    queryKey: [GET_TEMPLATE_BY_ID, templateId],
    queryFn: () => getTemplateById(templateId),
    enabled: !!templateId,
  });

  return {
    template,
    error,
    isTemplateLoading,
    templateId,
  };
};

import React from 'react';

import classes from './Unauthorized.module.css';
import { StyledCard } from "../shared/styles/styled";

const Unauthorized = () => {
  return (
    <StyledCard>
      <div className={classes.topMenu}>
        <h3>Unauthorized</h3>
      </div>
      <div className={classes.text}>Please contact admin about page permissions.</div>
    </StyledCard>
  );
};

export default Unauthorized;

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDeviceType, DEVICES } from '../utils/helperFunctions';
import routes from '../config/routes';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { dontShowTabletWarningModalAgainState, tabletWarningModalOpenState } from '../store';

const useDeviceCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setShowWarningModal = useSetRecoilState(tabletWarningModalOpenState);
  const isDontShowTabletWarning = useRecoilValue(dontShowTabletWarningModalAgainState);

  useEffect(() => {
    const deviceType = getDeviceType();

    if (location.pathname !== routes.REGISTER) {
      if (deviceType === DEVICES.MOBILE) {
        navigate(routes.WARNING);
      } else if (deviceType === DEVICES.TABLET) {
        if (!isDontShowTabletWarning) setShowWarningModal(true);
      }
    }
  }, [navigate]);
};

export default useDeviceCheck;

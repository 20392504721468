import React from 'react';
import { StyledFlex, StyledText } from '../shared/styles/styled';
import { StyledButton } from '../shared/REDISIGNED/controls/Button/StyledButton';
import { getTemplateEditorRoute, removeUnderscoreAndCapitalize } from '../WorkflowEditor/utils/helperFunctions';
import { useNavigate } from 'react-router-dom';

const ViewTemplateModal = ({ onClose, template, templateEditorId }) => {
  const navigate = useNavigate();

  const handleViewTemplate = () => {
    const route = getTemplateEditorRoute(templateEditorId, template?.templateType);
    navigate(route);
    onClose();
  };

  return (
    <StyledFlex p="50px" gap="40px">
      <StyledFlex alignItems="center" justifyContent="center" gap="25px">
        <StyledText weight={600} size={24}>
          Want to View Your Template?
        </StyledText>
        <StyledText textAlign="center">
          We added {template?.templateName} automatically when you created your account. You can view it now, or find it
          later in the {removeUnderscoreAndCapitalize(template?.templateType)} Manager.
        </StyledText>
      </StyledFlex>
      <StyledFlex gap="15px" direction="row" alignSelf="center">
        <StyledButton variant="outlined" primary onClick={onClose}>
          Check It Out Later
        </StyledButton>
        <StyledButton variant="contained" primary onClick={handleViewTemplate}>
          View Your Template
        </StyledButton>
      </StyledFlex>
    </StyledFlex>
  );
};

export default ViewTemplateModal;

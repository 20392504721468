import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import ReactGA from 'react-ga4';
import { clarity } from 'react-microsoft-clarity';
import { promiseUserOptionsDefault } from '../Components/shared/REDISIGNED/selectMenus/UserAutocomplete/helpers';
import { defaultUsers } from '../store';

const DataProvider = ({ user, children }) => {
  const setUsers = useSetRecoilState(defaultUsers);

  useEffect(() => {
    if (user.id) {
      // Set the user id for Google Analytics
      ReactGA.set({ userId: user.id });

      if (clarity.hasStarted()) {
        clarity.identify(user.id, user);
      }
    }
  }, [user.id]);

  useEffect(() => {
    promiseUserOptionsDefault().then((users) => setUsers(users));
  }, []);

  return <>{children}</>;
};

DataProvider.propTypes = {
  children: PropTypes.node,
};

export default DataProvider;

import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from '../Services/axios/userAxios';
import { useEffect } from "react";
import { LOCAL_STORAGE_KEYS } from "../utils/constants";
import routes from "../config/routes";

export const GET_CURRENT_USER = 'GET_CURRENT_USER';

export const useGetCurrentUser = (options = {}) => {
  const {
    data: currentUser,
    error: currentUserError,
    isLoading: isLoadingCurrentUser,
    isFetching: isFetchingCurrentUser,
    refetch: refetchCurrentUser,
    isError,
  } = useQuery({
    queryKey: [GET_CURRENT_USER],
    queryFn: () => getCurrentUser(),
    gcTime: Infinity,
    staleTime: Infinity,
    ...options
  });

  useEffect(() => {
    if (isError) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.CART);
      window.location = routes.DEFAULT;
    }
  }, [isError]);

  return {
    currentUser,
    currentUserError,
    isLoadingCurrentUser,
    isFetchingCurrentUser,
    refetchCurrentUser,
  };
};

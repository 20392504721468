import { DATA_TYPES } from '../../Settings/Components/FrontOffice/components/ChatWidget/ChatWidgetCreateOrEdit/ChatWidgetPreview/ChatWidgetView/utils/constants/common';
import { STATIC_DYNAMIC_PARAM_TYPES, stepTypes } from '../constants/graph';
import { removeConditionalNode, removeGroupNode, removeNode } from '../services/graph';
import { ZOOM_LEVELS } from '../../Managers/shared/components/ZoomControl/ZoomDropdownMenu';
import routes from '../../../config/routes';
import { ISSUE_ENTITY_TYPE } from '../../Issues/constants/core';
import { generatePath } from 'react-router-dom';

export const getActionKey = (stepSettings, { when, includes, then, otherwise }) => {
  let key;

  if (when) {
    const whenValue = stepSettings.find((setting) => setting.stepSettingTemplate.promptText === when)?.currentValue;

    if (includes.includes(whenValue)) {
      key = then;
    } else {
      key = otherwise;
    }
  }

  return key;
};

export const getParamStaticDynamicText = (paramValue, paramType) => {
  try {
    const children = JSON.parse(paramValue || null)?.root?.children[0].children;

    return children?.some((el) => el.type === 'param')
      ? STATIC_DYNAMIC_PARAM_TYPES.DYNAMIC
      : STATIC_DYNAMIC_PARAM_TYPES.STATIC;
  } catch {
    return paramType;
  }
};

export const handleRemoveStep = ({ graph, stepId, editingStep, set, state }) => {
  if ([stepTypes.LOOP_START, stepTypes.RPA_START].includes(graph.getNodeAttribute(stepId, 'stepType'))) {
    removeGroupNode(graph, stepId);
  } else {
    removeNode(graph, stepId);
  }

  const isDeletedStepOpened = editingStep?.stepId === stepId;
  set({ ...state, editingStep: isDeletedStepOpened ? null : editingStep, workflow: graph.export() });
};

export const handleRemoveConditionalStep = ({ graph, stepId, state, set }) => {
  removeConditionalNode(graph, stepId);

  set({ ...state, workflow: graph.export() });
};

export const formattedWorkflowFileUpload = ({ id, name }) => ({
  type: DATA_TYPES.FILE,
  id,
  name,
});

export const removeUnderscoreAndCapitalize = (str) => {
  if (!str) return '';
  return str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const roundScrollValue = (scrollValue) => {
  return ZOOM_LEVELS.reduce((prev, curr) => {
    return Math.abs(curr - scrollValue) < Math.abs(prev - scrollValue) ? curr : prev;
  });
};

export const getTemplateEditorRoute = (templateEditorId, templateType) => {
  const route = {
    [ISSUE_ENTITY_TYPE.AGENT]: generatePath(routes.AGENT_MANAGER_DIAGRAM, { serviceTypeId: templateEditorId }),
    [ISSUE_ENTITY_TYPE.PROCESS]: generatePath(routes.PROCESS_MANAGER_INFO, { processId: templateEditorId }),
  };

  return route[templateType];
};

export const sanitizeParameterName = (name) => {
  if (typeof name !== 'string') return name;

  const NAME_REGEXP = /[^a-zA-Z0-9-._]/g;

  const sanitizedName = name.replace(NAME_REGEXP, '');

  return sanitizedName;
};

import React from 'react';

import { modifyStringENUMS } from '../utils/helperFunctions';

import routes, { modifyTitlesKeysENUM } from './routes';

// Main Components
const Chat = React.lazy(() => import('../Components/Chat/Chat'));
const Files = React.lazy(() => import('../Components/Files/Files'));
const Support = React.lazy(() => import('../Components/Support/Support'));
const ServiceTickets = React.lazy(() => import('../Components/Issues/components/ServiceTickets/ServiceTickets'));
const FalloutTickets = React.lazy(() => import('../Components/Issues/components/FalloutTickets/FalloutTickets'));
const BoaDashboard = React.lazy(() => import('../Components/BoaDashboard/BoaDashboard'));
const ConverseDashboard = React.lazy(() => import('../Components/ConverseDashboard/ConverseDashboard'));
const ProfileContainer = React.lazy(() => import('../Components/Profile/ProfileContainer'));

const AgentManager = React.lazy(() => import('../Components/Managers/AgentManager/AgentManager'));

const MR_Manager = React.lazy(() => import('../Components/Migrate/MR_Manager/MR_Manager'));
const MrHistory = React.lazy(() => import('../Components/Migrate/MrHistory/MrHistory'));
const MappingEditor = React.lazy(() => import('../Components/Migrate/MR_Manager/MappingEditor/MappingEditor'));

const ProcessManager = React.lazy(() => import('../Components/Managers/ProcessManager/ProcessManager'));
const ProcessEditor = React.lazy(() => import('../Components/WorkflowEditor/ProcessEditor'));
const TicketsFullView = React.lazy(
  () => import('../Components/Issues/components/ServiceTickets/components/TicketsFullView/TicketsFullView')
);
const FalloutTicketsFullView = React.lazy(
  () =>
    import('../Components/Issues/components/FalloutTickets/components/FalloutTicketsFullView/FalloutTicketsFullView')
);

const ConversationHistory = React.lazy(() => import('../Components/ConversationHistory/ConversationHistory'));
const ProcessOrchestration = React.lazy(
  () => import('../Components/Managers/OrchestrationManager/ProcessOrchestratorList/ProcessOrchestratorList')
);
const ProcessOrchestrationEditor = React.lazy(
  () => import('../Components/Managers/OrchestrationManager/ProcessOrchestratorEditor/ProcessOrchestratorEditor')
);
const ProcessOrchestrationDetails = React.lazy(
  () => import('../Components/Managers/OrchestrationManager/ProcessOrchestratorDetails/ProcessOrchestratorDetails')
);
const ProcessOrchestrationHistory = React.lazy(
  () => import('../Components/Managers/OrchestrationManager/ProcessOrchestrationHistory/ProcessOrchestrationHistory')
);
const LiveChat = React.lazy(() => import('../Components/Chat/Chat'));
const AgentEditor = React.lazy(() => import('../Components/Managers/AgentManager/AgentEditorsCombiner'));
const Unauthorized = React.lazy(() => import('../Components/Unauthorized/Unauthorized'));
const MySummary = React.lazy(() => import('../Components/MySummary/MySummary'));
const ProcessHistory = React.lazy(() => import('../Components/ProcessHistory/ProcessHistory'));
const ProcessTrigger = React.lazy(() => import('../Components/ProcessTrigger/ProcessTrigger'));
const EventTriggerDetails = React.lazy(
  () => import('../Components/ProcessTrigger/Components/EventTriggers/EventTriggerDetails/EventTriggerDetails')
);
const BulkPreviewFullView = React.lazy(
  () => import('../Components/ProcessHistory/BulkPreviewFullView/BulkPreviewFullView')
);
const ProcessDataVisualizer = React.lazy(
  () => import('../Components/ProcessDataVisualizer/Components/ProcessDataVisualizer')
);

// Settings Components
const GeneralSettings = React.lazy(() => import('../Components/Settings/Components/General/GeneralSettings'));
const CreateKnowledgeBase = React.lazy(
  () =>
    import(
      '../Components/Settings/Components/General/components/SimplyAssistantKnowledgeBases/components/CreateKnowledgeBase'
    )
);
const AccessManagement = React.lazy(() => import('../Components/Settings/AccessManagement/AccessManagement'));
const ProfileTab = React.lazy(() => import('../Components/Settings/AccessManagement/components/ProfileTab/ProfileTab'));
const ChangePassword = React.lazy(
  () => import('../Components/Settings/AccessManagement/components/ChangePassword/ChangePassword')
);
const EditUser = React.lazy(() => import('../Components/Settings/AccessManagement/components/EditUser/EditUser'));
const PermissionGroupsView = React.lazy(
  () => import('../Components/Settings/AccessManagement/views/PermissionGroups/PermissionGroupsView')
);
const PermissionGroupDetails = React.lazy(
  () => import('../Components/Settings/AccessManagement/components/PermissionGroupDetails/PermissionGroupDetails')
);
const UserGroupTab = React.lazy(
  () => import('../Components/Settings/AccessManagement/components/UserGroupTab/UserGroupTab')
);
const FrontOffice = React.lazy(() => import('../Components/Settings/Components/FrontOffice/FrontOffice'));
const ChatWidgetCreateOrEdit = React.lazy(
  () =>
    import(
      '../Components/Settings/Components/FrontOffice/components/ChatWidget/ChatWidgetCreateOrEdit/ChatWidgetCreateOrEdit'
    )
);

const BackOfficeSettings = React.lazy(
  () => import('../Components/Settings/SubRoutes/BackOfficeSettings/BackOfficeSettings')
);
const ParametersSetPage = React.lazy(
  () => import('../Components/Settings/Components/EnvironmentsAndParameters/components/ParametersSet/ParametersSet')
);

const Billing = React.lazy(() => import('../Components/Settings/SubRoutes/BillingSettings/Billing'));
const Usage = React.lazy(() => import('../Components/Settings/Components/Usage/Usage'));

const CreateServiceTicketType = React.lazy(
  () =>
    import('../Components/Settings/Components/FrontOffice/components/CreateServiceTicketType/CreateServiceTicketType')
);

// Comment Settings components as we are migrating to new Settings

// Test Components
const TestManager = React.lazy(() => import('../Components/Managers/TestManager/TestManager'));
const TestDataFullView = React.lazy(
  () => import('../Components/Managers/TestManager/components/TestDataFullView/TestDataFullView')
);

const TestEditor = React.lazy(() => import('../Components/WorkflowEditor/TestEditor'));
const TestHistory = React.lazy(() => import('../Components/TestComponents/TestHistory/TestHistory'));
const TestDashboard = React.lazy(() => import('../Components/TestComponents/TestDashboard/TestDashboard'));

// Sell Components
const CustomerManager = React.lazy(() => import('../Components/Sell/Customer/CustomerManager'));
const CustomerProfile = React.lazy(() => import('../Components/Sell/Customer/CustomerProfile/CustomerProfile'));
const OrderManager = React.lazy(() => import('../Components/Sell/Orders/OrderManager'));
const OrderDetails = React.lazy(() => import('../Components/Sell/Orders/OrderDetails/OrderDetails'));
const ProductOfferings = React.lazy(() => import('../Components/Sell/Orders/ProductOfferings/ProductOfferings'));
const ProductOfferingsCheckout = React.lazy(
  () => import('../Components/Sell/Orders/ProductOfferings/ProductOfferingsCheckout/ProductOfferingsCheckout')
);
const ProductOfferingsConfirmation = React.lazy(
  () => import('../Components/Sell/Orders/ProductOfferings/ProductOfferingsConfirmation/ProductOfferingsConfirmation')
);

const AgentTester = React.lazy(() => import('../Components/AgentTester/AgentTester'));

const appRoutes = [
  // MAIN/NAVBAR routes
  {
    pathName: routes.DEFAULT,
    component: MySummary,
  },
  {
    pathName: routes.UNAUTHORIZED,
    component: Unauthorized,
  },
  {
    pathName: routes.CHAT,
    component: Chat,
  },

  {
    pathName: routes.TICKETS,
    component: ServiceTickets,
  },
  {
    pathName: routes.TEST_MANAGER,
    component: TestManager,
  },
  {
    pathName: routes.TEST_CASE_DETAILS,
    component: TestDataFullView,
  },
  {
    pathName: routes.TEST_SUITE_DETAILS,
    component: TestDataFullView,
  },
  {
    pathName: routes.TEST_GROUP_DETAILS,
    component: TestDataFullView,
  },
  {
    pathName: routes.TEST_EDITOR_INFO,
    component: TestEditor,
  },
  {
    pathName: routes.TEST_HISTORY,
    component: TestHistory,
  },
  {
    pathName: routes.FALLOUT_TICKETS,
    component: FalloutTickets,
  },
  {
    pathName: routes.SETTINGS_CREATE_SERIVCE_TICKET_TYPE,
    component: CreateServiceTicketType,
  },
  {
    pathName: routes.SETTINGS_EDIT_SERIVCE_TICKET_TYPE,
    component: CreateServiceTicketType,
  },
  {
    pathName: routes.AGENT_MANAGER,
    component: AgentManager,
  },
  {
    pathName: routes.CONVERSATION_HISTORY,
    component: ConversationHistory,
  },
  {
    pathName: routes.PROCESS_ORCHESTRATION,
    component: ProcessOrchestration,
  },
  {
    pathName: routes.PROCESS_ORCHESTRATION_DETAILS,
    component: ProcessOrchestrationDetails,
  },
  {
    pathName: routes.PROCESS_ORCHESTRATION_EDIT,
    component: ProcessOrchestrationEditor,
  },
  {
    pathName: routes.PROCESS_ORCHESTRATION_HISTORY,
    component: ProcessOrchestrationHistory,
  },
  {
    pathName: routes.CHAT,
    component: LiveChat,
  },
  {
    pathName: routes.BOA_DASHBOARD,
    component: BoaDashboard,
  },
  {
    pathName: routes.CONVERSE_DASHBOARD,
    component: ConverseDashboard,
  },
  {
    pathName: routes.TEST_DASHBOARD,
    component: TestDashboard,
  },
  {
    pathName: routes.PROCESS_MANAGER,
    component: ProcessManager,
  },
  {
    pathName: routes.PROCESS_MANAGER_INFO,
    component: ProcessEditor,
  },
  {
    pathName: routes.PROCESS_TRIGGER,
    component: ProcessTrigger,
  },
  {
    pathName: routes.EVENT_TRIGGER_DETAILS,
    component: EventTriggerDetails,
  },
  {
    pathName: routes.EVENT_TRIGGER_DETAILS_EDIT,
    component: EventTriggerDetails,
  },
  {
    pathName: routes.PROCESS_DATA_VISUALIZER,
    component: ProcessDataVisualizer,
  },
  {
    pathName: routes.PROCESS_HISTORY,
    component: ProcessHistory,
  },
  {
    pathName: routes.PROCESS_HISTORY_BULK_PREVIEW,
    component: BulkPreviewFullView,
  },
  {
    pathName: routes.FILES,
    component: Files,
  },
  {
    pathName: routes.SUPPORT,
    component: Support,
  },

  // MAIN routes
  {
    pathName: routes.TICKETS_FULLVIEW,
    component: TicketsFullView,
  },
  {
    pathName: routes.FALLOUT_TICKETS_FULL_VIEW,
    component: FalloutTicketsFullView,
  },
  {
    pathName: routes.AGENT_MANAGER_DIAGRAM,
    component: AgentEditor,
  },
  {
    pathName: routes.AGENT_TESTER,
    component: AgentTester,
  },
  {
    pathName: routes.PROFILE,
    component: ProfileContainer,
  },
  {
    pathName: routes.PROFILE_CHANGE_PASSWORD,
    component: ChangePassword,
  },
  {
    pathName: routes.PROFILE_EDIT,
    component: EditUser,
  },

  // SETTINGS routes

  {
    pathName: routes.SETTINGS_GENERAL_TAB,
    component: GeneralSettings,
  },
  {
    pathName: routes.SETTINGS_GENERAL_TAB_CREATE_KNOWLEDGE_BASE,
    component: CreateKnowledgeBase,
  },
  {
    pathName: routes.SETTINGS_GENERAL_TAB_EDIT_KNOWLEDGE_BASE,
    component: CreateKnowledgeBase,
  },
  {
    pathName: routes.SETTINGS_ACCESS_MANAGER,
    component: AccessManagement,
  },
  {
    pathName: routes.SETTINGS_ACCESS_MANAGER_USER_DETAILS,
    component: ProfileTab,
  },
  {
    pathName: routes.SETTINGS_ACCESS_MANAGER_USER_CHANGE_PASSWORD,
    component: ChangePassword,
  },
  {
    pathName: routes.SETTINGS_ACCESS_MANAGER_EDIT_USER,
    component: EditUser,
  },
  {
    pathName: routes.SETTINGS_ACCESS_MANAGER_PERMISSION_GROUPS,
    component: PermissionGroupsView,
  },
  {
    pathName: routes.SETTINGS_ACCESS_MANAGER_PERMISSION_GROUP_DETAILS,
    component: PermissionGroupDetails,
  },
  {
    pathName: routes.SETTINGS_USER_GROUPS_SINGLE_ITEM,
    component: UserGroupTab,
  },
  {
    pathName: routes.SETTINGS_USER_GROUPS_SINGLE_ITEM,
    component: UserGroupTab,
  },
  {
    pathName: routes.SETTINGS_FRONT_OFFICE_TAB,
    component: FrontOffice,
  },
  {
    pathName: routes.SETTINGS_CREATE_CHAT_WIDGET,
    component: ChatWidgetCreateOrEdit,
  },
  {
    pathName: routes.SETTINGS_EDIT_CHAT_WIDGET,
    component: ChatWidgetCreateOrEdit,
  },
  {
    pathName: routes.SETTINGS_BACK_OFFICE_TAB,
    component: BackOfficeSettings,
  },
  {
    pathName: routes.SETTINGS_CREATE_PARAMETER_SET,
    component: ParametersSetPage,
  },
  {
    pathName: routes.SETTINGS_EDIT_PARAMETER_SET,
    component: ParametersSetPage,
  },
  {
    pathName: routes.SETTINGS_BILLING_TAB,
    component: Billing,
  },
  {
    pathName: routes.SETTINGS_USAGE_TAB,
    component: Usage,
  },
  // MIGRATE
  {
    pathName: routes.MR_MANAGER,
    component: MR_Manager,
  },
  {
    pathName: routes.MR_HISTORY,
    component: MrHistory,
  },
  {
    pathName: routes.MR_MANAGER_MAPPING_EDITOR,
    component: MappingEditor,
  },
  {
    pathName: routes.CUSTOMER_MANAGER,
    component: CustomerManager,
  },
  {
    pathName: routes.CUSTOMER_PROFILE,
    component: CustomerProfile,
  },
  {
    pathName: routes.ORDER_MANAGER,
    component: OrderManager,
  },
  {
    pathName: routes.PRODUCT_ORDER_HISTORY_DETAILS,
    component: OrderDetails,
  },
  {
    pathName: routes.PRODUCT_OFFERINGS,
    component: ProductOfferings,
  },
  {
    pathName: routes.PRODUCT_OFFERINGS_CHECKOUT,
    component: ProductOfferingsCheckout,
  },
  {
    pathName: routes.PRODUCT_OFFERINGS_CONFIRMATION,
    component: ProductOfferingsConfirmation,
  },
];

export const mapPermissionsWithPages = (pages) => {
  const BOA_DASHBOARD_KEYS = {
    processViewPathName: `${routes.BOA_DASHBOARD}/`,
    boaViewPathName: routes.BOA_DASHBOARD,
  };
  const appendAppRoutesToPaths = [];

  appRoutes.forEach((item) => {
    pages?.forEach((page) => {
      if (
        page?.pageUrlPath === item?.pathName ||
        (page?.pageUrlPath?.startsWith(BOA_DASHBOARD_KEYS.processViewPathName) &&
          item?.pathName?.startsWith(BOA_DASHBOARD_KEYS.boaViewPathName))
      ) {
        const pageTitle = page?.pageName;
        if (pageTitle?.includes(modifyTitlesKeysENUM.UNDERSCORE)) {
          page.title = modifyStringENUMS(pageTitle);
        }

        appendAppRoutesToPaths.push({ ...page, ...item });
      }
    });
  });
  return appendAppRoutesToPaths;
};

export const getTopItemsRedirectModels = (pages = []) => {
  return pages
    .filter((page) => {
      if (!page) {
        return false;
      }

      const isTopItem = page.isTopMenuItem;
      const noComponentToRender = !appRoutes.find((route) => route?.pathName === page.pageUrlPath);
      const hasChildrenRoutes = pages.some((route) => route.parentPageId === page.pageId);

      return isTopItem && noComponentToRender && hasChildrenRoutes;
    })
    .map((topItem) => {
      const childrenRoutes = pages.filter((childRoute) => childRoute.parentPageId === topItem.pageId);
      const highestOrderRoute = childrenRoutes.sort((a, b) => a.subMenuItemOrder - b.subMenuItemOrder)[0];

      return {
        route: topItem.pageUrlPath,
        redirect: highestOrderRoute.pageUrlPath,
      };
    });
};

export const getRoutesWithExactMatch = () => [routes.PRODUCT_OFFERINGS];

/* eslint-disable max-len */
import SvgIcon from '@mui/material/SvgIcon';

const OpenIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M6.73 20.98a4.19 4.19 0 0 1-3.7-3.71c-.04-.3-.04-8.96 0-9.25A4.2 4.2 0 0 1 6 4.45c.52-.15.57-.15 2.1-.16l1.49.01c.35.08.62.31.73.65.07.2.06.48-.04.68-.1.2-.25.37-.45.47l-.17.08-1.4.01c-1.5.02-1.53.02-1.85.14a2.34 2.34 0 0 0-1.46 1.65c-.03.14-.04.5-.04 4.54-.01 3.07 0 4.45.01 4.58.1.84.67 1.56 1.45 1.84.41.15.01.14 4.83.15 3.08 0 4.42 0 4.57-.02A2.22 2.22 0 0 0 17.79 17l.02-1.42c0-1.1 0-1.2.05-1.3.1-.3.32-.52.62-.62a.85.85 0 0 1 .33-.03c.19.01.23.02.36.08a1 1 0 0 1 .53.7c.03.15.03 2.43 0 2.77a4.25 4.25 0 0 1-1.53 2.89c-.5.4-1.13.7-1.74.84-.45.1-.43.1-5.09.09-2.43 0-4.5-.01-4.6-.02z" />
      <path d="M12.7 11.98a.81.81 0 0 1-.6-1.16c.04-.09.68-.74 3.1-3.14a415 415 0 0 0 3.03-3.06s-1.08-.02-2.4-.02h-2.39l-.14-.08a.82.82 0 0 1-.4-.41.87.87 0 0 1 .02-.61.75.75 0 0 1 .28-.35c.23-.16-.03-.15 3.78-.14h3.43l.13.07c.16.08.3.22.39.39l.06.13v3.42c.01 3.81.02 3.55-.13 3.78a.75.75 0 0 1-.35.29.87.87 0 0 1-.61 0 .82.82 0 0 1-.42-.38l-.07-.15v-2.4l-.03-2.38-3.03 3.01a251.53 251.53 0 0 1-3.1 3.07.87.87 0 0 1-.56.12z" />
    </SvgIcon>
  );
};

export default OpenIcon;

OpenIcon.propTypes = SvgIcon.propTypes;
